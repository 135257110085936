<template>
 <div class="content ">
   <Nav></Nav>
   <div class="cont_video" >
     <video ref="videoPlayer" data-v-07cc480a="" muted="muted" autoplay="autoplay" loop="loop" x5-playsinline="true" playsinline="true" webkit-playsinline="true" x-webkit-airplay="allow" preload="auto" x5-video-player-type="h5" x5-video-player-fullscreen="true"><source data-v-07cc480a=""  src="" type="video/mp4"></video>
   </div>
    <div class="cont_yp" @click="yp_but">
      <img v-if="isYp" src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/41.png" />
      <img v-else src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/40.png" />
    </div>

   <div class="cont_title" >
     <p>{{user.title}}</p>
     <p>{{user.subtitle}}</p>

   </div>
   <div class="cont_down">

     <div class="down_ios" @click="appxz(1)">
       <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/14.png"/>
       <div class="down_code" v-if="android">
         <img :src="user.appewm" alt="">
       </div>
     </div>
     <div class="down_android" @click="appxz(2)">
       <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/6.png" />
       <div class="down_code" v-if="ios">
         <img :src="user.appewm" alt="">
       </div>
     </div>
     <div class="down_app_code">
        <div class="app_code_div" v-for="(item,index) in app_list" :key="index" @mouseenter="app_list[index].type = 1" @mouseleave="app_list[index].type = 0">
          <img class="app_code_div_logo" :src="item.img" alt="">
          <div class="app_code_div_dialog" v-if="item.type">
            <img :src="item.ewm" alt="">
          </div>
        </div>
     </div>
   </div>
   <div class="cont_mountain" ref="dx1">
      <div v-show="dx1" class="mountain_div ">
         <p class="mountain_div_title animate__animated animate__delay-1s animate__fadeInUp ">一吸一呼 <br> 让心回家</p>
         <p class="mountain_div_titles animate__animated animate__delay-1s animate__fadeInUp ">寻找一个属于自己灵魂栖息地</p>

         <img class="animate__animated animate__delay-1s animate__fadeInUp " src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/29.png" alt="">
      </div>
   </div>
   <div class="cont_title_1">
         <div class="title_cont">
           <el-carousel :interval="5500" direction="vertical" arrow="never" indicator-position="none"  >
             <el-carousel-item v-for="(item,index) in yl_list" :key="index">
                <div v-html="item.info"></div>
             </el-carousel-item>
           </el-carousel>
         </div>
   </div>
   <div class="cont_mountain_1" ref="dx2">
       <div  v-show="dx2" class="mountain_div animate__animated animate__delay-1s  animate__fadeInUp">
            <div class="mountain_1_title-1">博古通今 <br> 包罗万象 冥想课程</div>
            <div class="mountain_1_title-2">从心理、睡眠、情绪、健康、身心 多方面心灵练习获得安静丰盛的冥 想力量。</div>
       </div>

   </div>
   <div class="cont_title_1">
     <div class="title_cont">
       <el-carousel :interval="4500" direction="vertical" arrow="never" indicator-position="none"  >
         <el-carousel-item v-for="(item,index) in yl_list" :key="index">
           <div v-html="item.info"></div>
         </el-carousel-item>
       </el-carousel>
     </div>
   </div>
   <div class="cont_mountain" ref="dx3">
     <div  v-show="dx3" class="mountain_div ">
       <p class="mountain_div_title animate__animated animate__delay-1s  animate__fadeInUp">一段音律 <br> 与大自然来一次共鸣平复心灵</p>
       <p class="mountain_div_titles animate__animated animate__delay-1s  animate__fadeInUp">心灵的声音如同大自然的天籁之音，它们悠扬地飘荡 在空气中，时而轻盈，时而激荡，沉浸其中感受当下 接受自我。</p>
       <img class="animate__animated animate__delay-1s  animate__fadeInUp" src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/32.png" alt="">
     </div>
   </div>
   <div class="cont_title_2">
     我呼吸  我行走  我发声  我存在
   </div>
   <div class="cont_map" ref="dx4">
     <div class="map_cont">
       <div class="map_roll">
         <div class="map_roll_div" ref="rollHeight" :style="{ transitionDuration: `${transitionDuration}ms`, transform: `translateY( -${translateX}px)`}">
            <div class="roll_item" >
              <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
              <div>
                <p>这里是张**</p>
                <p>刚完成了16分钟快速放松休息</p>
              </div>
            </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是李**</p>
               <p>刚完成了12分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是范**</p>
               <p>刚完成了17分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是邓**</p>
               <p>刚完成了8分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是金**</p>
               <p>刚完成了36分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是杨**</p>
               <p>刚完成了24分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是用朱**</p>
               <p>刚完成了19分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是蔡**</p>
               <p>刚完成了42分钟快速放松休息</p>
             </div>
           </div><div class="roll_item" >
           <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
           <div>
             <p>这里是用王**</p>
             <p>刚完成了27分钟快速放松休息</p>
           </div>
         </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是孙**</p>
               <p>刚完成了37分钟快速放松休息</p>
             </div>
           </div>


         </div>
       </div>
        <p  v-show="dx4"  class="map_title animate__animated animate__delay-1s  animate__fadeInUp">目前</p>
        <p  v-show="dx4" class="map_title_1 animate__animated animate__delay-1s  animate__fadeInUp">{{user.notitle}}全球用户数量 <br>已累计超过 <CountTo :startVal='startVal' :endVal='endVal' :duration='duration' /></p>
     </div>
   </div>

    <Btm></Btm>
 </div>
</template>

<script>
import Nav from "@/components/pc-Nav/index"
import Btm from "@/components/pc-Btmfooter/index"
import CountTo from 'vue-count-to'
import {all_list, all_mxyl} from "@/api/request/all";
import {mapState} from "vuex";


export default {
  name: "home",
  data(){
    return{
      app_list:[
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/12.png',type:0},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/15.png',type:0},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/24.png',type:0},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/28.png',type:0},
      ],
      translateX:0,
      transitionDuration:500,
      isYp:true,
      dx1:false,
      dx2:false,
      dx3:false,
      dx4:false,
      user:'',
      yl_list:'',
      ios:0,
      android:0,
      startVal:10,
      endVal: 10000000,
      duration: 100000,


    }
  },

  components:{
    Nav,
    Btm,
    CountTo
  },
  computed: {
    ...mapState(['user','seo']),
  },
  metaInfo() {
    return {
      title: this.seo[0].title || this.user.indextitle,
      meta: [
        { name: 'description', content: this.seo[0].description ||this.user.drp},
        { name: 'keywords', content:this.seo[0].keywords ||this.user.seo}
      ]
    };
  },
  created() {
      this.all_user();
  },
  mounted() {
   // document.title = this.seo[0].title || this.user.indextitle;
    this.all_yl();
    this.user_roll();
    window.addEventListener('scroll', this.handleScroll);
  },
  methods:{
    //app下载
    appxz(val){
      if (val == 1){
        window.open("https://apps.apple.com/cn/app/%E5%88%9D%E5%BF%83%E5%86%A5%E6%83%B3/id6464159165");
      }else {
        window.open("https://sj.qq.com/appdetail/uni.UNI39BA3F5");
      }
    },
    //数据请求
    all_user(){
      let list={}
      all_list(list).then((res)=>{
        if (res.status == 1){
          this.user =res.data;
          this.$refs.videoPlayer.src=res.data.bgurl;
          this.$nextTick(()=>{
            this.$refs.videoPlayer.muted =true;
            this.$refs.videoPlayer.play();
          })
          this.app_list[0].ewm=res.data.gzhewm;
          this.app_list[1].ewm=res.data.sphewm;
          this.app_list[2].ewm=res.data.wxewm;
          this.app_list[3].ewm=res.data.dyewm;
        }
      })
    },
    //冥想语录
    all_yl(){
      let list={}
      all_mxyl(list).then((res)=>{
        if (res.status == 1){
          for (let i=0; i<res.data.length; i++){
            res.data[i].info= this.convertNewLinesToBrTags(res.data[i].info);
          }
          this.yl_list=res.data;
        }
      })
    },
    //正则转换
     convertNewLinesToBrTags(text) {
       return text.replace(/(\r\n|\n|\r)/gm, "<br/>");
     },
    //背景音乐开启关闭
    yp_but(){
      this.isYp =!this.isYp;
      if (this.isYp){
        this.$nextTick(()=>{
          this.$refs.videoPlayer.muted =true;
          this.$refs.videoPlayer.play();
        })
      }else {
        this.$nextTick(()=>{
          this.$refs.videoPlayer.muted =false;
          this.$refs.videoPlayer.play();
        })
      }


    },
    //自动轮播
    user_roll(){
      // 滚动项个数
      const switeCount = 10;
      const translateValue = this.$refs.rollHeight.offsetHeight/switeCount;
      setInterval(() => {
        this.translateX =this.translateX + translateValue
        this.transitionDuration=500;
         if ( this.translateX == (translateValue * (switeCount-4)).toFixed(1)){
             setTimeout(()=>{
               this.transitionDuration = 0;
               this.translateX = translateValue;
             },500)


         }
      }, 2000)

    },
    //页面滚动监听 触发动效
    handleScroll() { // 实现当滚动到指定位置，触发动画
      // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
      // this.gdjz('earth', 'earth animated bounceInDown', 50)
      let _this = this
      let refArray = [
        {ref: 'dx1', show: 'dx1'},
        {ref: 'dx2', show: 'dx2'},
        {ref: 'dx3', show: 'dx3'},
        {ref: 'dx4', show: 'dx4'},
      ]
      refArray.forEach((r) => {
        _this.gdjz(r.ref, 20, () => {
          _this[r.show] = true
        })
      })
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div] // 等同于document.querySelector('.earth') true
      if(dom){
        var a,b,c,d;
        d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset)
        b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
        if( b + c > a ) {
          callback && callback()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .content{
    padding-top: 1px;
  // position: relative;
   overflow: hidden;
   .cont_video{
     width: 100%;
     position: fixed;
     z-index: -1;
     top: 0;
     video{
       width: 100%;
       height: 100%;
     }
   }
   .cont_yp{
     width: 63px;
     height: 63px;
     position: absolute;
     top: 100px;
     right: 300px;
     img{
       width: 63px;
       height: 63px;
     }
   }
   .cont_title{
     width: 100%;
     margin: 260px auto 0;
     text-align: center;
     p:nth-child(1){
       font-weight: bold;
       font-size: 73px;
       color: #FFFFFF;
       margin: 0;
     }
     p:nth-child(2){
       font-weight: bold;
       font-size: 33px;
       color: #FFFFFF;
       line-height: 88px;
       letter-spacing: 2px;
     }
   }
   .cont_down{
     width: 957px;
     margin: 130px auto  0;
     position: relative;
     height: 380px;

     .down_ios{
       width: 196px;
       height: 69px;
       position: absolute;
       top: 16px;
       left: 263px;
       img{
          width: 195px;
         height: 68px;
       }
       .down_code{
         background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/34.png') top center no-repeat;
         background-size: 100%;
         width: 206px;
         height: 210px;
         position: absolute;
         top: -230px;
         left: 50%;
         transform: translateX(-50%);
         text-align: center;
         img{
           width: 180px;
           height: 180px;
           margin-top: 11px;
         }
       }
     }
     .down_android{
       width: 196px;
       height: 69px;
       position: absolute;
       top: 16px;
       left: 513px;
       img{
         width: 196px;
         height: 69px;
       }
       .down_code{
         background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/34.png') top center no-repeat;
         background-size: 100%;
         width: 206px;
         height: 210px;
         position: absolute;
         top: -230px;
         left: 50%;
         transform: translateX(-50%);
         text-align: center;
         img{
           width: 180px;
           height: 180px;
           margin-top: 11px;
         }
       }
     }
      .down_app_code{
        width: 468px;
        height: 55px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 256px;
        top:209px;
        .app_code_div{
          width: 55px;
          height: 55px;
          position: relative;
          .app_code_div_logo{
            width: 55px;
            height: 55px;
          }
          .app_code_div_dialog{
            background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/34.png') top center no-repeat;
            background-size: 100%;
            width:206px;
            height: 209px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -215px;
            text-align: center;
            img{
              width: 180px;
              height: 180px;
              margin-top: 11px;
            }
          }
        }
      }
   }
   .cont_mountain{
     background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/7.png') top center no-repeat;
     background-size: 100%;
     width: 100%;
     height: 730px;

     .mountain_div{
       width: 1243px;
       position: relative;
       height: 730px;
       margin: 0 auto;
       .mountain_div_title{
         max-width: 651px;
         font-weight: bold;
         font-size: 48px;
         color: #333333;
         line-height: 62px;
         border-bottom:1px solid #C1C1C1;
         padding-bottom: 15px;
         position: absolute;
         top: 257px;
       }
       .mountain_div_titles{
         max-width: 650px;
         height: 27px;
         font-weight: bold;
         font-size: 28px;
         color: #333333;
         line-height: 48px;
         position: absolute;
         top: 440px;
       }
       img{
         width: 287px;
         height: 544px;
         position: absolute;
         right: 0;
         top: 13%;
         //transform: translateY(-50%);
       }

     }
   }
   .cont_title_1{
     background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/36.png') top center no-repeat;
     background-size: 100%;
     width: 100%;
     height: 298px;
     .title_cont{
       width: 1243px;
       height: 298px;
       overflow: hidden;
       margin: 0 auto;
       .el-carousel__item{
         width: 1243px;
         height: 298px;
         div{
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translateY(-50%) translateX(-50%);
           width: 748px;
           font-weight: bold;
           font-size: 30px;
           color: #FFFFFF;
           line-height: 48px;
         }
       }
     }

   }
   .cont_mountain_1{
     background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/40.gif') top center no-repeat;
     background-size: 100%;
     width: 100%;
     height: 880px;
     position: relative;
     .mountain_div{
       width: 1243px;
       position: relative;
       height: 730px;
       margin: 0 auto;
       .mountain_1_title-1{
         font-weight: bold;
         font-size: 48px;
         color: #222222;
         line-height: 62px;
         position: absolute;
         top: 210px;
         right: 0;
         border-bottom: 1px solid #C1C1C1;
         padding-bottom: 15px;
       }
       .mountain_1_title-2{
         width: 423px;
         font-weight: bold;
         font-size: 28px;
         color: #333333;
         line-height: 48px;
         position: absolute;
         top: 370px;
         right: 0;
       }
     }


   }
   .cont_title_2{
     width: 100%;
     height: 302px;
     font-weight: bold;
     font-size: 52px;
     color: #FFFFFF;
     line-height: 302px;
     text-align: center;
     letter-spacing: 3px;
   }
   .cont_map{
     background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/41.gif') top center no-repeat;
     background-size: 100%;
     width: 100%;
     height: 590px;
     .map_cont{
       width: 1243px;
       height: 590px;
       margin: 0 auto;
       position: relative;
       .map_roll{
         width:230px;
         height: 232px;
         position: absolute;
         top:164px;
         overflow: hidden ;
         .map_roll_div{
           width: 100%;
           .roll_item{
             height: 42px;
             display: flex;
             padding: 8px 0;
             img{
               width: 42px;
               height: 42px;
               border-radius: 50%;
             }
             div{
               margin-left: 15px;
               padding-top: 4px;
               p:nth-child(1){
                 font-weight: 500;
                 font-size: 12px;
                 color: #333333;
               }
               p:nth-child(2){
                 font-weight: 500;
                 font-size: 12px;
                 color: #333333;

               }
             }
           }
         }
       }
       .map_title{
         width: 116px;
         height: 52px;
         background: #FFA71F;
         border-radius: 9px;
         text-align: center;
         line-height: 52px;
         font-weight: bold;
         font-size: 34px;
         color: #FFFFFF;
         position: absolute;
         top: 198px;
         left: 700px;
       }
       .map_title_1{
         width: 500px;
         height: 86px;
         font-weight: bold;
         font-size: 35px;
         color: #333333;
         line-height: 51px;
         position: absolute;
         top: 271px;
         left: 700px;
         span{
           font-weight: bold;
           font-size: 50px;
           color: #FFA71F;
         }
       }
     }
   }
 }
</style>